<template>
  <div class="page-diy">
    <v-container>
      <div class="DIY-row single">
        <div class="wizard-body video">
          <p class="med-bold-question">Menu: Click on a Page to Visit</p>
          <div>
            <table class="w-100">
              <thead>
                <tr>
                  <th class="header-black-and-white">General Information</th>
                  <th class="header-black-and-white">Owner's Perks</th>
                  <th class="header-black-and-white">Valuation Multiple</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <a href="javascript:void(0)" @click="showCashFlow13"
                      >Business Name:</a
                    >
                  </td>
                  <td>
                    <a href="javascript:void(0)" @click="showCashFlow21"
                      >Owner's Perks:</a
                    >
                  </td>
                  <td>
                    <a href="javascript:void(0)" @click="showCashFlow32"
                      >Valuation Multiple:</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href="javascript:void(0)" @click="showCashFlow14"
                      >Reporting Year:</a
                    >
                  </td>
                  <td>
                    <a href="javascript:void(0)" @click="showCashFlow24"
                      >Car Insurance:</a
                    >
                  </td>
                  <td>
                    <a href="javascript:void(0)" @click="showCashFlow33"
                      >Cashflow Level:</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href="javascript:void(0)" @click="showCashFlow15"
                      >Sales Revenues:</a
                    >
                  </td>
                  <td>
                    <a href="javascript:void(0)" @click="showCashFlow25"
                      >Health Insurance:</a
                    >
                  </td>
                  <td>
                    <a href="javascript:void(0)" @click="showCashFlow35"
                      >Desirable Industry:</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href="javascript:void(0)" @click="showCashFlow16"
                      >Employee Count:</a
                    >
                  </td>
                  <td>
                    <a href="javascript:void(0)" @click="showCashFlow26"
                      >Life Insurance:</a
                    >
                  </td>
                  <td>
                    <a href="javascript:void(0)" @click="showCashFlow36"
                      >Employee Scarcity:</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href="javascript:void(0)" @click="showCashFlow17"
                      >Net Income:</a
                    >
                  </td>
                  <td>
                    <a href="javascript:void(0)" @click="showCashFlow27"
                      >Retirement Accounts:</a
                    >
                  </td>
                  <td>
                    <a href="javascript:void(0)" @click="showCashFlow37"
                      >Customer Concentration:</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href="javascript:void(0)" @click="showCashFlow18"
                      >Owner Salary & PR Taxes:</a
                    >
                  </td>
                  <td>
                    <a href="javascript:void(0)" @click="showCashFlow28"
                      >Charitable Contributions:</a
                    >
                  </td>
                  <td>
                    <a href="javascript:void(0)" @click="showCashFlow38"
                      >Specialized:</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href="javascript:void(0)" @click="showCashFlow19"
                      >Amortization & Depreciation:</a
                    >
                  </td>
                  <td>
                    <a href="javascript:void(0)" @click="showCashFlow29"
                      >Meals & Entertainment:</a
                    >
                  </td>
                  <td>
                    <a href="javascript:void(0)" @click="showCashFlow39"
                      >Owner Involvement 1:</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href="javascript:void(0)" @click="showCashFlow110"
                      >Interest Expense:</a
                    >
                  </td>
                  <td>
                    <a href="javascript:void(0)" @click="showCashFlow210"
                      >Memberships:</a
                    >
                  </td>
                  <td>
                    <a href="javascript:void(0)" @click="showCashFlow310"
                      >Owner Involvement 2:</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href="javascript:void(0)" @click="showCashFlow112"
                      >Real Estate:</a
                    >
                  </td>
                  <td>
                    <a href="javascript:void(0)" @click="showCashFlow211"
                      >Travel:</a
                    >
                  </td>
                  <td>
                    <a href="javascript:void(0)" @click="showCashFlow311"
                      >Management Team:</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href="javascript:void(0)" @click="showCashFlow113"
                      >Rent Variance:</a
                    >
                  </td>
                  <td>
                    <a href="javascript:void(0)" @click="showCashFlow212"
                      >Family Compensation:</a
                    >
                  </td>
                  <td>
                    <a href="javascript:void(0)" @click="showCashFlow312"
                      >Highly Profitable:</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href="javascript:void(0)" @click="showCashFlow111"
                      >Non Recurring:</a
                    >
                  </td>
                  <td>
                    <a href="javascript:void(0)" @click="showCashFlow213"
                      >Misc. Expense:</a
                    >
                  </td>
                  <td>
                    <a href="javascript:void(0)" @click="showCashFlow313"
                      >Financial Trends:</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href="javascript:void(0)" @click="showCashFlow13"></a>
                  </td>
                  <td>
                    <a href="javascript:void(0)" @click="showCashFlow13"></a>
                  </td>
                  <td>
                    <a href="javascript:void(0)" @click="showCashFlow314"
                      >Age of Business:</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href="javascript:void(0)" @click="showCashFlow42"
                      >Confirm Page 1:</a
                    >
                  </td>
                  <td>
                    <a href="javascript:void(0)" @click="showCashFlow44"
                      >Confirm Page 3:</a
                    >
                  </td>
                  <td>
                    <a href="javascript:void(0)" @click="showCashFlow315"
                      >Intellectual Property:</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href="javascript:void(0)" @click="showCashFlow43"
                      >Confirm Page 2:</a
                    >
                  </td>
                  <td>
                    <a href="javascript:void(0)" @click="showCashFlow46"
                      >Request Report Page:</a
                    >
                  </td>
                  <td>
                    <a href="javascript:void(0)" @click="showCashFlow316"
                      >Disruptor:</a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div>
            <button class="menu-button-back" @click="showIntro">
              BACK TO START
            </button>
            <button class="menu-button-logout"  @click="logOut">
              LOG OUT
            </button>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import CommonServices from "../service/CommonServices";
import { mask } from "vue-the-mask";
import { EventBus } from "../main";

export default {
  components: {},
  directives: {
    mask,
  },

  created() {
    this.checkPermissions();   

    //this.checkPermissions();

    localStorage.setItem("menuID", 5); //signals called pages to presend 'BACK' button to this page

    this.usCurrency = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    this.getDealData(); //retrieves data for the DIY Multiple

    CommonServices.getItem(this.componentURL_CBR)
      .then((resID) => {
        console.log("Current CBR Response: ", resID);
        console.log("Current CBR ID: ", resID.results[0].id);
        this.getCBRData(resID.results[0].id);
      })
      .catch((err) => {
        console.log(err);
      });

    CommonServices.getItem(this.componentURL)
      .then((res) => {
        console.log("Response: ", res);
        if (res.count == 0) {
          this.getYearlyData();
          this.wsData.net_income = 0;
        } else {
          console.log("Current Year: ", this.wsData.work_year);
          console.log("Current CBR ID: ", res.results[0].id);
          this.getData(res.results[0].id);
          console.log("Current Year: ", this.wsData.work_year);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },

  data() {
    return {
      componentURL: `CashflowDIY/?user_id=${this.$route.params.id}`,
      componentURL_base: `CashflowDIY`,
      canpost: localStorage.getItem("canpost"),
      candelete: localStorage.getItem("candelete"),
      canput: localStorage.getItem("canput"),
      isAdmin: localStorage.getItem("Admin"),
      isBroker: localStorage.getItem('role')==2,
      isSeller: localStorage.getItem('role')==3,  
      isBuyer: localStorage.getItem('role')==4,
      isDIY: localStorage.getItem('role')==7,
      role: localStorage.getItem('role'),
      valid: false,
      dialog: false,
      dialogDelete: false,
      dialogPerks: false,
      dialogSelectUser: false,
      dlgTOC: false,
      deletedId: null,
      snackbar: false,
      snackColor: "",
      snackText: "",
      error: false,
      loading: false,
      editedIndex: -1,
      searchText: "",
      file: "",
      profileImageUrl: "",
      currentDeal: localStorage.getItem("Deal"),
      currentUserID: localStorage.getItem("userid"),
      show: false,
      variants: [
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "light",
        "dark",
      ],
      headerBgVariant: "success",
      headerTextVariant: "light",
      bodyBgVariant: "light",
      bodyTextVariant: "dark",
      footerBgVariant: "success",
      footerTextVariant: "dark",
      errorTitle: "",
      errorText: "",
      dlgError: false,
      usCurrency: null,

      workYear: new Date().getFullYear() - 1,
      companyName: "",

      headers: [
        { text: "Actions", value: "actions", sortable: false },
        { text: "Question", value: "city", sortable: true },
      ],

      items: [],
      list_years: [
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
        "2023",
        "2024",
        "2025",
        "2026",
        "2027",
        "2028",
      ],

      wsData: {
        id: "",
        deal_id: this.$route.params.id,
        company_name: "",
        work_year: new Date().getFullYear() - 1,
        net_income: 0,
        salary_taxes: 0,
        amortization: 0,
        int_exp: 0,
        non_recur_exp: 0,
        car_pay_owner: 0,
        car_pay_Spouse: 0,
        car_ins_owner: 0,
        car_ins_spouse: 0,
        health_ins_owner: 0,
        health_ins_spouse: 0,
        life_ins_owner: 0,
        life_ins_spouse: 0,
        ira: 0,
        charitable_contrib: 0,
        meals: 0,
        memberships: 0,
        travel: 0,
        family_comp: 0,
        other: 0,
        other_a: 0,
        other_b: 0,
      },

      multipleData: {
        id: "",
        user_id: this.$route.params.id,
        company_name: localStorage.getItem("Deal"),
        work_year: new Date().getFullYear() - 1,
        question_text: "",
        description: "",
        question_answer: null,
        question_id: 0,
        multiple: null,
      },

      money: {
        decimal: ".",
        thousands: ",",
        prefix: "$",
        suffix: " ",
        precision: 0,
        masked: false,
      },

      userActivityItem: {
        user_id: localStorage.getItem("userid"),
        logdate: this.$moment(String(Date())).format("YYYY-MM-DDThh:mm"),
        page: "CashFlowCalculator",
        action: "",
        deal_id: "",
        json: "",
      },

      mask: {
        maxDollar: "############",
        zipCode: "#####",
        ssn: "###-##-####",
        value: "######",
        phone: "(###) ###-####",
      },
    };
  },

  computed: {
    cashFlow() {
      return (
        this.wsData.net_income +
        this.wsData.salary_taxes +
        this.wsData.amortization +
        this.wsData.int_exp +
        this.wsData.non_recur_exp +
        this.perks
      );
    },

    perks() {
      return (
        this.wsData.car_pay_owner +
        this.wsData.car_pay_Spouse +
        this.wsData.car_ins_owner +
        this.wsData.car_ins_spouse +
        this.wsData.health_ins_owner +
        this.wsData.health_ins_spouse +
        this.wsData.life_ins_owner +
        this.wsData.life_ins_spouse +
        this.wsData.ira +
        this.wsData.charitable_contrib +
        this.wsData.meals +
        this.wsData.memberships +
        this.wsData.travel +
        this.wsData.family_comp +
        this.wsData.other +
        this.wsData.other_a +
        this.wsData.other_b
      );
    },
  },

  methods: {
    checkPermissions() {
      console.log('UserID/RouteId',this.currentUserID,this.$route.params.id)
      if(this.currentUserID !==this.$route.params.id){
        if (this.isAdmin == "false"){
         this.$router.push("/");
        }
      }       
    },

    logOut() {
      localStorage.clear();
      EventBus.$emit("toggle-nav", null);
      this.$router.push({ path: "Login" });
    },
    


    getDealData() {
      this.companyName = this.multipleData.company_name;
      CommonServices.getList(`MultipleDIY/?user_id=${this.$route.params.id}`)
        .then((res) => {
          if (res.count == 0) {
            //create records for this deal/year
            this.createQuestions(this.$route.params.id);
          } else {
            //console.log("Current Year: ", this.multipleData.work_year);
            //console.log("Yearly Data: ", res);
            this.multipleData = res.results;
            this.multipleData.company_name = localStorage.getItem("Deal");
          }
          console.log("multipleData:", this.multipleData);
          console.log("First Question:", this.multipleData[0].question_text);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    showIntro() {
      localStorage.setItem("welcomeBack", 0); //disable Welcome Back flag so that the welcome page does not naviage back to this page
      this.saveFormData();
      this.dlgTOC = false;
      this.$router.push(`/DIYWelcome/${this.$route.params.id}`);
    },

    showIntroVideo() {
      this.saveFormData();
      this.dlgTOC = false;
      this.$router.push(`/DIYIntroVideo/${this.$route.params.id}`);
    },

    showCashFlow13() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_1-3/${this.$route.params.id}`);
    },

    showCashFlow14() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_1-4/${this.$route.params.id}`);
    },

    showCashFlow15() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_1-5/${this.$route.params.id}`);
    },

    showCashFlow16() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_1-6/${this.$route.params.id}`);
    },

    showCashFlow17() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_1-7/${this.$route.params.id}`);
    },

    showCashFlow18() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_1-8/${this.$route.params.id}`);
    },

    showCashFlow19() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_1-9/${this.$route.params.id}`);
    },

    showCashFlow110() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_1-10/${this.$route.params.id}`);
    },

    showCashFlow111() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_1-11/${this.$route.params.id}`);
    },

    showCashFlow112() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_1-12/${this.$route.params.id}`);
    },

    showCashFlow113() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_1-13/${this.$route.params.id}`);
    },

    showCashFlow21() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_2-1/${this.$route.params.id}`);
    },

    showCashFlow22() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_2-2/${this.$route.params.id}`);
    },

    showCashFlow23() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_2-3/${this.$route.params.id}`);
    },

    showCashFlow24() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_2-4/${this.$route.params.id}`);
    },

    showCashFlow25() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_2-5/${this.$route.params.id}`);
    },

    showCashFlow26() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_2-6/${this.$route.params.id}`);
    },

    showCashFlow27() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_2-7/${this.$route.params.id}`);
    },

    showCashFlow28() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_2-8/${this.$route.params.id}`);
    },

    showCashFlow29() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_2-9/${this.$route.params.id}`);
    },

    showCashFlow210() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_2-10/${this.$route.params.id}`);
    },

    showCashFlow211() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_2-11/${this.$route.params.id}`);
    },

    showCashFlow212() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_2-12/${this.$route.params.id}`);
    },

    showCashFlow213() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_2-13/${this.$route.params.id}`);
    },

    showCashFlow31() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_3-1/${this.$route.params.id}`);
    },

    showCashFlow32() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_3-2/${this.$route.params.id}`);
    },

    showCashFlow33() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_3-3/${this.$route.params.id}`);
    },

    showCashFlow34() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_3-4/${this.$route.params.id}`);
    },

    showCashFlow35() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_3-5/${this.$route.params.id}`);
    },

    showCashFlow36() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_3-6/${this.$route.params.id}`);
    },

    showCashFlow37() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_3-7/${this.$route.params.id}`);
    },

    showCashFlow38() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_3-8/${this.$route.params.id}`);
    },

    showCashFlow39() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_3-9/${this.$route.params.id}`);
    },

    showCashFlow310() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_3-10/${this.$route.params.id}`);
    },

    showCashFlow311() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_3-11/${this.$route.params.id}`);
    },

    showCashFlow312() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_3-12/${this.$route.params.id}`);
    },

    showCashFlow313() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_3-13/${this.$route.params.id}`);
    },

    showCashFlow314() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_3-14/${this.$route.params.id}`);
    },

    showCashFlow315() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_3-15/${this.$route.params.id}`);
    },

    showCashFlow316() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_3-16/${this.$route.params.id}`);
    },

    showCashFlow41() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_4-1/${this.$route.params.id}`);
    },

    showCashFlow42() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_4-2/${this.$route.params.id}`);
    },

    showCashFlow43() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_4-3/${this.$route.params.id}`);
    },

    showCashFlow44() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_4-4/${this.$route.params.id}`);
    },

    showCashFlow45() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_4-5/${this.$route.params.id}`);
    },

    showCashFlow46() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_4-6/${this.$route.params.id}`);
    },

    showCashFlow47() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_4-7/${this.$route.params.id}`);
    },

    showCashFlow48() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_4-8/${this.$route.params.id}`);
    },

    showTOC() {
      this.dlgTOC = true;
    },

    getYearlyData() {
      this.workYear = this.wsData.work_year;
      this.companyName = this.wsData.company_name;
      CommonServices.getList(
        `Cashflow/?work_year=${this.workYear}&deal_id=${this.$route.params.id}`
      )
        .then((res) => {
          console.log("Current Year: ", this.wsData.work_year);
          console.log("Res: ", res);
          if (res.count == 0) {
            this.resetItems();
            //this.wsData = res;
            //this.wsData = { ...this.wsData, ...res };
            console.log("Clean Worksheet", this.wsData);
            this.wsData.company_name = localStorage.getItem("Deal");
            this.wsData.deal_id = this.$route.params.id;
            this.wsData.work_year = this.workYear;

            //this.dialogPerks = true;
            //this.dialogPerks=false;
          } else {
            this.getData(res.results[0].id);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    resetItems() {
      console.log("Resetting items...");
      this.wsData = {
        id: "",
        deal_id: this.$route.params.id,
        company_name: "",
        net_income: 0,
        salary_taxes: 0,
        amortization: 0,
        int_exp: 0,
        non_recur_exp: 0,
        car_pay_owner: 0,
        car_pay_Spouse: 0,
        car_ins_owner: 0,
        car_ins_spouse: 0,
        health_ins_owner: 0,
        health_ins_spouse: 0,
        life_ins_owner: 0,
        life_ins_spouse: 0,
        ira: 0,
        charitable_contrib: 0,
        meals: 0,
        memberships: 0,
        travel: 0,
        family_comp: 0,
        other: 0,
        other_a: 0,
        other_b: 0,
      };
    },

    saveFormData() {
      console.log("Saving CaseFlow Data for UserID: ", this.wsData.user_id);
      this.wsData.last_page = "DIY_4-5";
      if (this.wsData.id) {
        console.log(
          "Updating CaseFlow Data for UserID: ",
          this.wsData.user_id,
          this.wsData.id
        );
        console.log("CashFlow Dataset: ", this.wsData);
        CommonServices.updateData(this.componentURL_base, this.wsData)
          .then((response) => {
            const index = this.items.findIndex(
              (item) => item.id === response.id
            );
            this.$set(this.items, index, response);
            this.snackbar = true;
            this.snackColor = "success";
            this.snackText = "Record successfully updated";
          })
          .catch((error) => {
            this.snackbar = true;
            this.snackColor = "error";
            try {
              Object.keys(error.response.data).forEach((key) => {
                this.snackText = error.response.data[key][0];
              });
            } catch (e) {
              this.snackText = "Something went wrong";
            }
          });
      } else {
        console.log("Adding CaseFlow Data for UserID: ", this.wsData.user_id);
        CommonServices.addRecord(this.componentURL_base, this.wsData)
          .then((response) => {
            this.items.push(response);
            this.snackbar = true;
            this.snackColor = "success";
            this.snackText = "Record successfully added";
          })
          .catch((error) => {
            this.snackbar = true;
            this.snackColor = "error";
            try {
              Object.keys(error.data).forEach((key) => {
                this.snackText = error.data[key][0];
              });
            } catch (e) {
              this.snackText = "Something went wrong";
            }
          });
      }
    },

    getData(ID) {
      console.log("Cashflow Detail Response: ", "test");
      CommonServices.Detail(ID, this.componentURL_base)
        .then((res) => {
          console.log("Cashflow Detail Response: ", res);
          this.wsData = { ...this.wsData, ...res };
          //this.dialogPerks = true;
          this.$forceUpdate();

          //this.dialogPerks=false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    goBack() {
      return this.$router.go(-1);
    },
  },
};
</script>

<style src="../css/styles.css" scoped></style>
